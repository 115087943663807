import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  TabItem,
  Tabs,
  Text
} from '@platform-storybook/circlestorybook';
import styles from './order-detail.module.scss';
import DetailTab from './tabs/detail-tab/DetailTab';
import { useTranslation } from 'react-i18next';
import { Order, OrderFile } from '../../../models/order';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadOrderFilesData } from '../../../features/file-manager/file.utils';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getOrderDetailsPagePermissionsSelector } from '../../../store/auth/permissions.selectors';
import { useLazyGetOneOrderQuery } from '../../../services/orders.services';
import { splitOrderNumber } from '../../../utils/utils';
import { useGetLabUsersQuery, useGetAllDentistsQuery } from '../../../services/users-rtkq.services';
import {
  useLazyGetOneDownloadableFileQuery,
  useDownloadFileFromStorageMutation
} from '../../../services/files-api.services';

const OrderDetailPage = () => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const { orderNumber } = useParams();
  const [getOneOrder, { data: orderData }] = useLazyGetOneOrderQuery();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const connectedUserPermissions = useAppSelector(getOrderDetailsPagePermissionsSelector);

  const [order, setOrder] = useState<Order | undefined>();

  const tabItems: ReactElement[] = [];
  if (connectedUserPermissions.canViewDetail) {
    tabItems.push(
      <TabItem
        key="detail"
        label={t('tabs.detail.title')}
        className={styles['order-detail__content__tab__content']}
        data-cy="detail-tab">
        {order && <DetailTab order={order} />}
      </TabItem>
    );
  }

  // Load users lab in order to have data already loaded for Comment and history tab.
  useGetLabUsersQuery();
  // Load dentist in order to have data already loaded for History tab.
  useGetAllDentistsQuery();

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
    if (orderNumber) getOneOrder(orderNumber);

    return () => {
      dispatch(ordersActions.resetOrder());
    };
  }, []);

  useEffect(() => {
    if (orderData && orderNumber) {
      setOrder(orderData);
      loadOrderFilesData(
        dispatch,
        getOneDownloadableFile,
        downloadFromStorage,
        orderNumber,
        orderData.patient?.diagnostic?.patientFiles as OrderFile[]
      );
    }
  }, [orderData]);

  const orderNumberSplit = orderNumber ? splitOrderNumber(orderNumber) : undefined;

  return (
    orderNumberSplit && (
      <div>
        <BreadcrumbsBar>
          <BreadcrumbItem text={t('link.orders', { ns: 'common' })} link="/orders" />
          <BreadcrumbItem link="#" text={t('title')} />
        </BreadcrumbsBar>
        <div className={styles['order-detail']}>
          <div className={styles['order-detail__content']}>
            <header className={styles['order-detail__content__header']}>
              <div className={styles['order-detail__content__header__title']}>
                {order?.isInError && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className={styles['order-detail__content__header__title--error']}
                    data-cy="order_detail_title_error"
                  />
                )}
                <Text
                  type="title"
                  label={orderNumberSplit.beforeLastPart}
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
                <Text
                  type="title"
                  label={orderNumberSplit.lastPart}
                  bold
                  size="s"
                  color={order?.isInError ? 'danger' : undefined}
                  data-cy="order_detail_title"
                />
              </div>
            </header>
            <Tabs
              activeTabId={0}
              id="order-detail-tabs"
              className={styles['order-detail__content__tab']}
              operatingMode="index">
              {tabItems.map((tabItem) => tabItem)}
            </Tabs>
          </div>
        </div>
      </div>
    )
  );
};

export default OrderDetailPage;
