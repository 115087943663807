import { AuthenticationReducer } from '../../models/user';

export interface ReducerState {
  authenticationState: AuthenticationReducer;
}

const userInfoSelector = (state: ReducerState) => state.authenticationState.userInfo;

const isAuthenticatedSelector = (state: ReducerState) => state.authenticationState.isAuthenticated;

export { userInfoSelector, isAuthenticatedSelector };
