import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import {
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../models/datagrid.tsx';
import { getLocalizedProperty } from '../../utils/utils.tsx';

export const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean, // true by default
  showColumnMenuTool?: boolean
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    header: header,
    showColumnMenuTool: false,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (!sortable) {
    column = { ...column, sortable: sortable };
  }
  if (typeof showColumnMenuTool !== 'undefined') {
    column = { ...column, showColumnMenuTool: showColumnMenuTool };
  }
  return column;
};

// For now, this works for a list
// If we need to go further, we'll refactor it or rename it if needed
export const computeMasterDetailsHeight = (
  rowHeight: number,
  rowYMargin: number,
  detailsYMargin: number,
  propertiesToCheck: (object | string | undefined)[]
) => {
  const nbOfRows =
    1 + propertiesToCheck.reduce((number, property) => (property ? number + 1 : number), 0);

  return nbOfRows * (rowHeight + rowYMargin) + detailsYMargin;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort += `${urlSort}&sortBy=${getLocalizedProperty(sort?.name)}:${
        sort?.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};
