export enum FileLabelEnum {
  UPPER = 'Upper',
  LOWER = 'Lower',
  OCCLUSION_KEY = 'OcclusionKey',
  FACESCAN = 'Facescan',
  AUXILIARY_FACESCAN = 'AuxiliaryFacescan',
  UPPER_AUXILIARY = 'UpperAuxiliary',
  LOWER_AUXILIARY = 'LowerAuxiliary',
  UPPER_WAXUP = 'UpperWaxup',
  LOWER_WAXUP = 'LowerWaxup',
  UPPER_WAX_RIM = 'UpperWaxRim',
  LOWER_WAX_RIM = 'LowerWaxRim',
  IGNORED = 'Ignored',
  UNKNOWN = 'Unknown',
  PHOTO = 'photo',
  VIDEO = 'video',
  TEXTURE = 'Texture',
  CANAL_IMPRESSION = 'CanalImpression',
  UPPER_PRESCAN = 'UpperPrescan',
  LOWER_PRESCAN = 'LowerPrescan'
}
