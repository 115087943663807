import { api } from './api';
import { QueryParams, ServicePaginatedResponse } from '../models/datagrid';
import { Order } from '../models/order';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneOrder: builder.query<Order, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}`,
        method: 'GET'
      })
    }),
    getOrders: builder.query<ServicePaginatedResponse, QueryParams>({
      query: (arg) => {
        const { page, limit, filters, sort } = arg;
        return `/orders?page=${page}&filter.deletedAt=$null&filter.items.deletedAt=$null&limit=${limit}${filters}${sort}`;
      }
    })
  })
});

export const { useLazyGetOneOrderQuery, useLazyGetOrdersQuery } = ordersApi;
