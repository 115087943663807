import { useCallback, useEffect, useState } from 'react';
import DatagridFeature from '../../../../features/datagrid/DatagridFeature';
import {
  getColumnSettings,
  getFilters,
  masterDetailsHeight,
  renderMasterDetails,
  rowClassName
} from './products';

import { FilterReducer, LoadDataProps, SettingsReducer } from '../../../../models/datagrid';
import { getProducts } from '../../../../services/products.services';
import { useTranslation } from 'react-i18next';
import styles from './products-page.module.scss';
import { Button, SideBarModal } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers';
import { productsDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors';
import { productsActions } from '../../../../store/products/products.reducers';
import { Product } from '../../../../models/product';
import { getValue } from '../components-page/components';
import ActivateProductForLabForm from './activate-product-form-for-lab/ActivateProductForLabForm';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { ToastType } from '../../../../enum/feedback';
import { getSort } from '../../../../features/datagrid/datagrid.utils.ts';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../../services/common-types-rtkq.services.ts';
import { isConnectedUserAdminSelector } from '../../../../store/users/users.selectors.tsx';
import { getLocalizedProperty } from '../../../../utils/utils.tsx';

const ProductsPage = () => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const datagridSettings: SettingsReducer = useAppSelector(productsDatagridStateSelector);
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [product, setProduct] = useState<Product>();
  const [isActivatedProductLoading, setIsActivatedProductLoading] = useState<boolean>(false);
  const isConnectedUserAdmin = useAppSelector(isConnectedUserAdminSelector);

  const loadData = ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);
    return getProducts(page, limit, filters, sort)
      .then((products) => {
        return { data: products.data.data, count: products.data.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const datasource = useCallback(loadData, [commonTypes, isActivatedProductLoading]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'products',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'products',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };
  const activateLabCallback = async (product: Product) => {
    setProduct(product);
    setSideBarOpened(true);
  };

  const onSubmitLabActivation = async (labName: string) => {
    handleClickCloseSidebar();
    setIsActivatedProductLoading(true);
    dispatch(
      feedbackActions.setToast({
        message: t('products.datagrid.activateLab.activated', { laboratory: labName }),
        type: ToastType.SUCCESS
      })
    );
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
  };

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClickCloseSidebar();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  return (
    <>
      <div className={styles['products-page__actions']}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={() => {
            dispatch(datagridSettingsActions.resetProductsDatagridFilters());
          }}
        />
        {isConnectedUserAdmin && (
          <Button
            iconRight="plus"
            label={t('products.datagrid.actions.createProduct')}
            onClick={() => {
              dispatch(productsActions.setProduct(undefined));
              navigate('/catalog/products/create');
            }}
          />
        )}
      </div>
      {sideBarOpened && product && (
        <SideBarModal
          title={t('products.datagrid.activateLab.title', {
            product: `${getValue(product, getLocalizedProperty('label'))}`
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={() => handleClickCloseSidebar()}>
          <ActivateProductForLabForm onSubmitCallback={onSubmitLabActivation} product={product} />
        </SideBarModal>
      )}
      {!areCommonTypesLoading && commonTypes && (
        <DatagridFeature
          key="products"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={datasource}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          columns={getColumnSettings(commonTypes, activateLabCallback, isConnectedUserAdmin)}
          rowClassName={rowClassName}
          // Master details
          renderRowDetails={renderMasterDetails}
          rowExpandHeight={masterDetailsHeight}
          multiRowExpand={false}
        />
      )}
    </>
  );
};

export default ProductsPage;
