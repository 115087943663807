import { ManufacturingOrder } from '../models/manufacturing-order';
import { api } from './api';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneManufacturingOrder: builder.query<ManufacturingOrder, string>({
      query: (orderNumber: string) => ({
        url: `${prefix_path}orders/${orderNumber}`,
        method: 'GET'
      })
    }),
    deleteManufacturingOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }) => ({
        url: `/manufacturing/orders/${orderNumber}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetOneManufacturingOrderQuery,
  useLazyGetOneManufacturingOrderQuery,
  useDeleteManufacturingOrderMutation
} = manufacturingOrdersApi;
