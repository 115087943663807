import { api } from './api';
import { Product, ProductUpdateForm } from '../models/product';
import { ServicePaginatedResponse } from '../models/datagrid';
import { getLocalizedProperty } from '../utils/utils';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveProducts: builder.query<ServicePaginatedResponse, { filters?: string; sort?: string }>(
      {
        query: ({ filters, sort }) => {
          if (!sort) sort = `&sortBy=${getLocalizedProperty('label')}:ASC`;
          return `/products?page=1&limit=-1&filter.deletedAt=$null${filters || ''}${sort}`;
        },
        providesTags: ['Products']
      }
    ),
    getOneProduct: builder.query<Product, string | undefined>({
      query: (id) => {
        return `/products/${id}`;
      },
      providesTags: ['OneProduct']
    }),
    patchProduct: builder.mutation<Product, ProductUpdateForm>({
      query: ({ id, ...patch }) => ({
        url: `/products/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Products', 'OneProduct']
    })
  })
});

export const { useGetOneProductQuery, useGetActiveProductsQuery, usePatchProductMutation } =
  productsApi;
