import { OrdersReducer } from '../../models/order';

interface ReducerState {
  ordersState: OrdersReducer;
}

export const orderFilesSelector = (state: ReducerState) => {
  return state?.ordersState?.files;
};

export const orderFile3dToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.file3dToDisplay;
};

export const orderFileImageToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.fileImageToDisplay;
};

export const orderFileTextureToDisplaySelector = (state: ReducerState) => {
  return state?.ordersState?.fileTextureToDisplay;
};

export const isLoadingFilesSelector = (state: ReducerState) => {
  return state.ordersState.isLoadingFiles;
};
