import { OrderDetailPagePermissions } from '../../models/connected-user.tsx';
import { ReducerState } from './auth.selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';

const selectConnectedUser = (state: ReducerState) => state.authenticationState.userInfo;

const getOrderDetailsPagePermissionsSelector = createSelector(
  [selectConnectedUser],
  (connectedUser): OrderDetailPagePermissions => {
    return {
      canViewDetail: !!connectedUser,
      canViewComments: !!connectedUser,
      canViewHistory: !!connectedUser,
      canViewDentistInfo: !!connectedUser
    };
  }
);

export { getOrderDetailsPagePermissionsSelector };
