import { ProductCreationForm, ProductsReducer } from '../../models/product';
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialProducts: ProductsReducer = {
  product: {
    labelFr: '',
    labelEn: '',
    family: '',
    category: undefined,
    class: '',
    circleCadVersion: '',
    circleCadProductType: '',
    teethMode: '',
    stumpMode: '',
    proportionalBilling: false,
    injectionMode: undefined,
    isChairSide: false,
    isOneDesign: false,
    components: undefined,
    productRule: undefined,
    productCompatibilities: undefined,
    imageUrl: ''
  },
  productDetails: undefined,
  products: undefined
};

export const productsSlice = createSlice({
  name: 'products',
  initialState: initialProducts,
  reducers: {
    setProduct: (state, action: PayloadAction<ProductCreationForm | undefined>) => {
      state.product = action.payload;
    },
    resetProduct: (state) => {
      state.product = initialProducts.product;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProductsReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialProducts };
    });
  }
});

export const productsActions = productsSlice.actions;
