import { PositionKey } from '../models/position';

export const OrderedLowerPositions: PositionKey[] = [
  48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
];
Object.freeze(OrderedLowerPositions);

export const OrderedUpperPositions: PositionKey[] = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28
];
Object.freeze(OrderedUpperPositions);

export const sortedPositionsNumber: PositionKey[] = [
  ...OrderedUpperPositions,
  ...OrderedLowerPositions
];
Object.freeze(sortedPositionsNumber);
