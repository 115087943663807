import React from 'react';
import { Box, BubblePicto, Chips, Text } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import styles from './order-item-card.module.scss';
import { useTranslation } from 'react-i18next';
import { Product } from '../../models/product';
import i18next from '../../i18n';
import { OrderItem, OrderItemComponent } from '../../models/order';
import { CardDisplayMode, Family, ProductCategory } from '../../enum/product.enum';
import {
  getMaterialColor,
  isCategoryProvisional,
  sortComponents,
  sortTeethPosition
} from '../../utils/order.utils';
import { ComponentType, MaterialEnum } from '../../enum/component-type';
import { TeethPositions } from '../teeth-positions/TeethPositions';
import { getComponentInItemByType, getItemDentalArch } from '../../utils/teeth-map.utils';
import { getLocalizedProperty } from '../../utils/utils';

type OrderItemCardProps = {
  item: OrderItem;
  displayMode?: CardDisplayMode;
};

export const OrderItemCard = ({
  item,
  displayMode = CardDisplayMode.DEFAULT
}: OrderItemCardProps) => {
  const { t } = useTranslation(['orderDetail']);
  const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);

  const isPrintDisplayMode = displayMode === CardDisplayMode.PRINT;

  // Display provisional or definitive category (no need to display unit, bridge etc category)
  const finalCategory = (category: ProductCategory): string => {
    return isCategoryProvisional(category)
      ? t(`products.categories.${ProductCategory.PROVISIONAL}`, { ns: 'catalog' })
      : t(`products.categories.${ProductCategory.DEFINITIVE}`, { ns: 'catalog' });
  };

  const displayMaterialChips = (component: OrderItemComponent): React.JSX.Element => {
    return (
      <Chips
        firstLabel={t(`material.${component?.material?.code}`, { ns: 'component' })}
        secondLabel={getOrderItemStratificationLabel(component)}
        color={getMaterialColor(component?.material?.code as MaterialEnum)}
      />
    );
  };

  const getOrderItemStratificationLabel = (component: OrderItemComponent): string | null => {
    return component?.material?.stratification
      ? t(`material.stratification.${component?.material?.stratification}`, {
          ns: 'component'
        })
      : null;
  };

  const displayShadeChips = (component: OrderItemComponent): React.JSX.Element => {
    return (
      <Chips
        color={ColorPropsEnum[component?.shade?.code as keyof typeof ColorPropsEnum]}
        firstLabel={i18next.t(`shade.${component?.shade?.code?.toUpperCase()}`, {
          ns: 'component'
        })}
      />
    );
  };

  const displayComponent = (component: OrderItemComponent): React.JSX.Element => {
    const isToothOrPartialTooth = [ComponentType.TOOTH, ComponentType.PARTIAL_TOOTH].includes(
      component.componentType
    );
    return (
      <>
        {isToothOrPartialTooth && (
          <div className={styles[`order-item-card__content__line`]}>
            <Text
              className={styles[`order-item-card__content__line__title`]}
              label={t('orderItemCard.positions')}
              bold={true}
            />
            <div className={styles[`order-item-card__content__line__data`]}>
              {component?.teethPositions && (
                <TeethPositions teethPositions={component?.teethPositions} />
              )}
            </div>
          </div>
        )}
        <div className={styles[`order-item-card__content__line`]} key={component.id}>
          <Text
            className={styles[`order-item-card__content__line__title`]}
            label={
              isToothOrPartialTooth &&
              component?.teethPositions &&
              component.teethPositions.length > 0
                ? t('orderItemCard.tooth', {
                    count: component.teethPositions.length
                  })
                : t(`orderItemCard.${component.componentType.toLowerCase()}`)
            }
            bold={true}
          />

          <div className={styles[`order-item-card__content__line__data`]}>
            {component?.material && displayMaterialChips(component)}
            {component?.shade && displayShadeChips(component)}
            {component?.aspect && (
              <Text
                label={t(`aspect.${component?.aspect.code}`, {
                  ns: 'component'
                })}
              />
            )}
            {component?.shape && (
              <Text
                label={i18next.t(`shape.${component?.shape?.code?.toUpperCase()}`, {
                  ns: 'component'
                })}
              />
            )}
          </div>
        </div>
        {!!component?.structures?.length && (
          <div
            className={styles[`order-item-card__content__line`]}
            key={`structures${component.id}`}>
            <Text
              className={styles[`order-item-card__content__line__title`]}
              label={t('orderItemCard.structure', {
                count: component.structures.length
              })}
              bold={true}
            />
            <div className={styles[`order-item-card__content`]}>
              {component.structures.map((structure) => {
                return (
                  <div
                    key={`${component.id}${structure.structure.id}`}
                    className={styles[`order-item-card__content__line__data__line`]}>
                    <Chips
                      color="black"
                      firstLabel={t(`structure.${structure.structure.code}`, {
                        ns: 'component'
                      })}
                      secondLabel={
                        structure.positions ? sortTeethPosition(structure.positions).join(', ') : ''
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Box
      color={isPrintDisplayMode ? 'white' : 'grey'}
      padding={isPrintDisplayMode ? 'spacing-1' : 'spacing-5'}
      key={item.id}
      className={[styles[`order-item-card--${displayMode}`], styles['order-item-card']].join(' ')}
      data-cy="order-detail-item">
      <div className={styles[`order-item-card__header`]}>
        <div className={styles[`order-item-card__header__product`]}>
          <BubblePicto
            size="small"
            color={
              ColorPropsEnum[
                `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
              ]
            }
            backgroundColor={isPrintDisplayMode ? ColorPropsEnum.WHITE : ColorPropsEnum.PRIMARY}
            url={item.product.imageUrl}
            isDashedBorder={item?.product?.category && isCategoryProvisional(item.product.category)}
            className={styles[`order-item-card__header__product__img`]}
            data-cy="order-detail-item-bubble"
          />
          <div className={styles[`order-item-card__header__product__label`]}>
            <Text
              label={item.product[getLocalizedProperty('label') as keyof Product] as string}
              bold={true}
              data-cy="order-detail-item-product"
            />
            <div className={styles[`order-item-card__header__product__attributes`]}>
              {toothComponent?.toothStratificationTechnique && (
                <Text
                  label={t(
                    `toothStratificationTechnique.${toothComponent?.toothStratificationTechnique}`,
                    {
                      ns: 'component'
                    }
                  )}
                />
              )}
              {item.product.family === Family.REMOV && (
                <Text
                  label={t(`arch.${getItemDentalArch(item)}`, {
                    ns: 'component'
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles[`order-item-card__header__category`]}>
          {item.product.category && (
            <Chips
              firstLabel={finalCategory(item.product.category)}
              color={ColorPropsEnum.BLACK}
              size={'m'}
            />
          )}
        </div>
      </div>
      {Object.assign([], item?.itemComponents)
        .filter((component: OrderItemComponent) => component.componentType !== ComponentType.MODELS)
        ?.sort(sortComponents)
        .map((component: OrderItemComponent, index) => {
          return <div key={`${component.id}-${index}`}>{displayComponent(component)}</div>;
        })}
    </Box>
  );
};
