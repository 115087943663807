import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { ToastType } from '../../enum/feedback';
import { FileLabelEnum } from '../../enum/file-label';
import { FeedBack, Toast } from '../../models/feedback';
import { OrderFile } from '../../models/order';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { ordersActions } from '../../store/orders/orders.reducer';
import { getMessageError } from '../../utils/utils';
import FileSaver from 'file-saver';
import {
  useLazyGetOneDownloadableFileQuery,
  useDownloadFileFromStorageMutation
} from '../../services/files-api.services';

const allowedThumbprintExtensions = ['stl', 'ply', 'obj'];
const allowedImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'heic', 'heif', 'mtl'];

type LazyGetOneDownloadableFileQueryTuple = ReturnType<typeof useLazyGetOneDownloadableFileQuery>;
type LazyGetOneDownloadableFileQueryTrigger = LazyGetOneDownloadableFileQueryTuple[0];
type DownloadFileFromStorageMutationTuple = ReturnType<typeof useDownloadFileFromStorageMutation>;
type DownloadFileFromStorageMutationTrigger = DownloadFileFromStorageMutationTuple[0];

export const isImageExtension = (extension: string) =>
  allowedImageExtensions.includes(extension?.toLowerCase());

export const isThumbprintExtension = (extension: string) =>
  allowedThumbprintExtensions.includes(extension?.toLowerCase());

export const getTextureFile = async (
  file3D: OrderFile,
  orderFiles: OrderFile[]
): Promise<OrderFile | undefined> => {
  if (file3D) {
    // Header attribute value to describe the texture file name in 3D file
    const textureFileHeader = 'comment TextureFile ';

    const header = await getFile3dHeader(file3D);
    if (header.includes(textureFileHeader)) {
      let textureFileName = header.substring(
        header.indexOf(textureFileHeader) + textureFileHeader.length
      );
      // Get texture file name in the file3D header
      textureFileName = textureFileName.substring(0, textureFileName.indexOf('\n'));
      // Search if the texture file has been uploaded
      const textureFile = orderFiles.filter(
        (file) =>
          replaceNonASCII(file.fileName + '.' + file.extension) === replaceNonASCII(textureFileName)
      );
      if (textureFile?.length) {
        return textureFile[0];
      }
    }
  }
};

const replaceNonASCII = (str: string) => {
  const asciiRange = `${String.fromCharCode(0)}-${String.fromCharCode(127)}`;
  const nonASCIIRegex = new RegExp(`[^${asciiRange}]`, 'g');
  return str.replace(nonASCIIRegex, '?');
};

const getFile3dHeader = async (file3D: OrderFile): Promise<string> => {
  const response = await fetch(URL.createObjectURL(file3D.data as Blob));
  const data: string = await response.text();
  // Return only the header of the file
  return data.substring(0, data.indexOf('end_header'));
};

export const downloadFile = (file: OrderFile): void => {
  FileSaver.saveAs(file.data as Blob, `${file.fileName}.${file.extension}`);
};

/**
 * Display fin in viewer using redux for creation order
 * @param {OrderFile} orderFiles
 * @param {OrderFile} fileToDisplay
 * @param {Dispatch<PayloadAction<OrderFile>>} dispatch
 */
export const displayFileInViewer = async (
  orderFiles: OrderFile[],
  fileToDisplay: OrderFile,
  dispatch: Dispatch<
    PayloadAction<OrderFile | OrderFile[] | boolean | undefined> | PayloadAction<boolean>
  >
): Promise<void> => {
  resetFiles(dispatch);
  if (orderFiles?.length && fileToDisplay?.data) {
    const alreadyLoadedFile = orderFiles.find(
      (thisFile) => areSameFiles(fileToDisplay, thisFile) && thisFile.data
    );

    if (alreadyLoadedFile) {
      if (isThumbprintExtension(fileToDisplay.extension)) {
        await set3DFile(dispatch, alreadyLoadedFile, orderFiles);
      }

      if (isImageExtension(fileToDisplay.extension)) {
        dispatch(ordersActions.setFileImageToDisplay(alreadyLoadedFile));
      }
    }
  }
};

/**
 * Reset all file redux states.
 * @param dispatch
 */
const resetFiles = (
  dispatch: Dispatch<PayloadAction<OrderFile | OrderFile[] | boolean | undefined>>
) => {
  dispatch(ordersActions.setFile3dToDisplay(undefined));
  dispatch(ordersActions.setFileTextureToDisplay(undefined));
  dispatch(ordersActions.setFileImageToDisplay(undefined));
};

/**
 * Set 3D and texture files redux states.
 * @param dispatch
 * @param fileToSet
 * @param orderFiles
 */
const set3DFile = async (
  dispatch: Dispatch<PayloadAction<OrderFile | OrderFile[] | boolean | undefined>>,
  fileToSet: OrderFile,
  orderFiles: OrderFile[]
): Promise<void> => {
  // Display file
  dispatch(ordersActions.setFile3dToDisplay(fileToSet));
  // Then check if there is an associated texture to this file
  // (We need to wait because we need the actual file for checking texture)
  const fileTexture = await getTextureFile(fileToSet, orderFiles);
  dispatch(ordersActions.setFileTextureToDisplay(fileTexture));
};

const setLoadedFile = (
  dispatch: (value: PayloadAction<OrderFile[] | boolean>) => void,
  updatedOrderFiles: OrderFile[],
  orderFile: OrderFile,
  fileLabel?: FileLabelEnum,
  blobFile?: BlobPart
): OrderFile[] | undefined => {
  if (orderFile && updatedOrderFiles?.length) {
    updatedOrderFiles = updatedOrderFiles.map((file) =>
      areSameFiles(file, orderFile)
        ? {
            ...file,
            data: blobFile ? new File([blobFile], orderFile.fileName) : file.data,
            fileLabel: fileLabel ?? file.fileLabel,
            isLoading: undefined
          }
        : file
    );
    dispatch(ordersActions.setFiles(updatedOrderFiles));
    dispatch(ordersActions.setLoadingFiles(false));
    return updatedOrderFiles;
  }
};

const setLoadingFile = (
  dispatch: (value: PayloadAction<OrderFile[] | boolean>) => void,
  updatedOrderFiles: OrderFile[],
  orderFile: OrderFile
): OrderFile[] => {
  updatedOrderFiles = updatedOrderFiles.map((file) =>
    areSameFiles(file, orderFile)
      ? {
          ...file,
          isLoading: true
        }
      : file
  );
  dispatch(ordersActions.setFiles(updatedOrderFiles));
  dispatch(ordersActions.setLoadingFiles(true));
  return updatedOrderFiles;
};

/**
 * Loads order files data.
 *
 * @param dispatch - The dispatch function for sending actions.
 * @param getOneDownloadableFile - The function for getting a downloadable file.
 * @param downloadFromStorage - The function for downloading a file from storage.
 * @param orderNumber - The order number.
 * @param orderFiles - The order files.
 * @returns void
 */
export const loadOrderFilesData = (
  dispatch: Dispatch<PayloadAction<OrderFile[] | FeedBack | boolean | Toast>>,
  getOneDownloadableFile: LazyGetOneDownloadableFileQueryTrigger,
  downloadFromStorage: DownloadFileFromStorageMutationTrigger,
  orderNumber: string,
  orderFiles: OrderFile[]
): void => {
  let loadedFiles: OrderFile[] = Object.assign([], orderFiles);
  const filesToDownload = loadedFiles?.filter((file) => !file.data && file.id);
  Promise.all(
    filesToDownload.map((fileToDownload) => {
      loadedFiles = setLoadingFile(dispatch, loadedFiles, fileToDownload);

      getOneDownloadableFile({ orderNumber, fileId: fileToDownload.id! })
        .unwrap()
        .then((downloadableFile) => {
          if (downloadableFile?.link) {
            downloadFromStorage({ url: downloadableFile.link })
              .unwrap()
              .then((blobFile) => {
                loadedFiles = setLoadedFile(
                  dispatch,
                  loadedFiles,
                  downloadableFile,
                  downloadableFile.fileLabel,
                  blobFile
                )!;
              });
          }
        })
        .catch((error) => {
          setLoadedFile(dispatch, orderFiles, fileToDownload);
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    })
  );
};

export const areSameFiles = (file: OrderFile, otherFile: OrderFile): boolean =>
  file?.fileName === otherFile?.fileName && file?.extension === otherFile?.extension;
