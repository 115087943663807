import PublicApp from './pages/public/PublicApp';
import ErrorPage from './pages/error-page/ErrorPage';
import ProtectedRoute from './auth/ProtectedRoute';
import LoginPage from './pages/public/login-page/LoginPage';
import PageNotFound from './pages/not-found-page/NotFoundPage';
import PrivateApp from './pages/private/PrivateApp';
import DashboardPage from './pages/private/dashboard-page/DashboardPage';
import ProfilePage from './pages/private/profile-page/ProfilePage';
import PreferencesPage from './pages/private/preferences-page/PreferencesPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import UsersPage from './pages/private/users-page/UsersPage';
import CreateUserPage from './pages/private/users-page/create-user-page/CreateUserPage';
import UserDetailPage from './pages/private/users-page/detail-user-page/UserDetailPage';
import ComponentsPage from './pages/private/catalog-page/components-page/ComponentsPage';
import CreateComponentPage from './pages/private/catalog-page/components-page/create-component-page/CreateComponentPage';
import CatalogPage from './pages/private/catalog-page/CatalogPage';
import ProductsPage from './pages/private/catalog-page/products-page/ProductsPage';
import ComponentDetailsPage from './pages/private/catalog-page/components-page/component-details-page/ComponentDetailsPage';
import CreateProductPage from './pages/private/catalog-page/products-page/create-product-page/CreateProductPage';
import ProductDetailsPage from './pages/private/catalog-page/products-page/product-details-page/ProductDetailsPage';
import OrdersPage from './pages/private/orders-page/OrdersPage';
import { useAppSelector } from './store/hooks';
import { isAuthenticatedSelector } from './store/auth/auth.selectors';
import ResetPasswordPage from './pages/public/reset-password-page/ResetPasswordPage';
import OrderDetailPage from './pages/private/order-detail-page/OrderDetailPage';

const RoutesAdmin = () => {
  // Redux authentication boolean and Firebase state of authentication to improve the security.
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route element={<PublicApp />} errorElement={<ErrorPage />}>
        <Route
          path="login"
          element={
            <ProtectedRoute redirectPath="/dashboard" isAllowed={!isAuthenticated}>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={!!isAuthenticated}>
            <PrivateApp />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route index path="dashboard" element={<DashboardPage />} />
        <Route path="orders" element={<OrdersPage />} />
        <Route path="/order/:orderNumber/detail" element={<OrderDetailPage />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="users/create" element={<CreateUserPage />} />
        <Route path="users/:email/detail" element={<UserDetailPage />} />
        <Route path="catalog/components/create" element={<CreateComponentPage />} />
        <Route path="/catalog/components/:id/detail" element={<ComponentDetailsPage />} />
        <Route
          path="catalog/products"
          element={
            <CatalogPage>
              <ProductsPage />
            </CatalogPage>
          }
        />
        <Route path="catalog/products/create" element={<CreateProductPage />} />
        <Route path="catalog/products/:id/detail" element={<ProductDetailsPage />} />
        <Route path="catalog/components/create" element={<CreateComponentPage />} />
        <Route
          path="catalog/components"
          element={
            <CatalogPage>
              <ComponentsPage />
            </CatalogPage>
          }
        />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="preferences" element={<PreferencesPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default RoutesAdmin;
