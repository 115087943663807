import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderFile, OrdersReducer } from '../../models/order';

const initialOrder: OrdersReducer = {
  files: [],
  error: undefined,
  file3dToDisplay: undefined,
  fileTextureToDisplay: undefined,
  fileImageToDisplay: undefined,
  isLoadingFiles: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    setFile3dToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.file3dToDisplay = action.payload;
    },
    setFileImageToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.fileImageToDisplay = action.payload;
    },
    setFileTextureToDisplay: (state, action: PayloadAction<OrderFile | undefined>) => {
      state.fileTextureToDisplay = action.payload;
    },
    setLoadingFiles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFiles = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;
