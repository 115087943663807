import { useEffect, useState } from 'react';
import {
  Chips,
  Divider,
  Link,
  Skeleton,
  Text,
  Toggle,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import styles from '../../order-detail.module.scss';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../enum/feedback';
import OrderDetailFile from './order-detail-file/OrderDetailFile';
import TeethMapDisplay from './teethmap-display/TeethMapDisplay';
import OrderDetailTimeline from './order-detail-timeline/OrderDetailTimeline';
import { OrderItemCard } from '../../../../../features/order-item-card/OrderItemCard';
import {
  capitalizeFirstLetter,
  getFormattedLocalizedMomentDate,
  getMessageError
} from '../../../../../utils/utils';
import { Order } from '../../../../../models/order';
import { getOrderDetailsPagePermissionsSelector } from '../../../../../store/auth/permissions.selectors.tsx';
import { useLazyGetOneManufacturingOrderQuery } from '../../../../../services/manufacturing-orders-rtkq.services.tsx';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order.tsx';
import { isSubmitted, isValidated, isModeled } from '../../../../../utils/order.utils.ts';

const DetailTab = ({ order }: { order: Order }) => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const connectedUserPermissions = useAppSelector(getOrderDetailsPagePermissionsSelector);
  const [getOneManufacturingOrder] = useLazyGetOneManufacturingOrderQuery();
  const [manufacturingOrder, setManufacturingOrder] = useState<ManufacturingOrder | undefined>();
  const isOrderSubmitted = isSubmitted(order?.currentStep);
  const isOrderValidated = isValidated(order?.currentStep);
  const isOrderManufactured = isModeled(order?.currentStep);

  useEffect(() => {
    // Property skipManufacturingOrderLoad is temporary and will be removed once pubsub is set up
    if (isOrderValidated) {
      getOneManufacturingOrder(order.orderNumber)
        .unwrap()
        .then((result: ManufacturingOrder) => {
          setManufacturingOrder(result);
        })
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  }, [isOrderValidated]);

  const toManufactureToggle = (
    <Toggle
      areLabelsHidden={true}
      id="toggle-to-manufacture"
      isChecked={order?.toManufacture}
      name="toggle-to-manufacture"
      isDisabled={true}
    />
  );

  return (
    <>
      <section
        className={styles['order-detail__content__tab__content__section']}
        data-cy="detail-tab-content">
        <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
          <div className={styles['order-detail__content__tab__content__section__tags']}>
            {order && !isOrderSubmitted && order?.rejectCauseComment && (
              <Tooltip>
                <TooltipContent
                  className={styles['order-detail__content__tab__content__section__tooltip']}
                  data-cy="rejected-order-tooltip">
                  <div
                    className={
                      styles['order-detail__content__tab__content__section__tooltip__row']
                    }>
                    <Text
                      color={ColorPropsEnum.WHITE}
                      bold={true}
                      label={t('tabs.detail.rejected.tooltip.motive', {
                        count: order?.rejectCauses?.length
                      })}
                    />
                    <Text
                      color={ColorPropsEnum.WHITE}
                      label={
                        order?.rejectCauses
                          ?.map((cause) => capitalizeFirstLetter(cause))
                          ?.join(', ') || '-'
                      }
                    />
                  </div>
                  <div>
                    <Text
                      color={ColorPropsEnum.WHITE}
                      bold={true}
                      label={t('tabs.detail.rejected.tooltip.comment')}
                    />
                    <Text color={ColorPropsEnum.WHITE} label={order?.rejectCauseComment} />
                  </div>
                </TooltipContent>
                <Chips
                  color="danger"
                  firstLabel={t('tabs.detail.rejected.title')}
                  firstIcon="fa-ban"
                  className={
                    styles['order-detail__content__tab__content__section__row--inlined__block']
                  }
                  data-cy="rejected-order-chip"
                />
              </Tooltip>
            )}
            {order?.tags?.map((tag) => <Chips key={tag} firstLabel={tag} />)}
          </div>
        </div>
        {order && isOrderSubmitted && !isOrderManufactured && (
          <div className={styles['order-detail__content__tab__content__section__toggle']}>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.orderToManufacture')} />
            {toManufactureToggle}
          </div>
        )}
        {!order && <Skeleton type="text" />}
        <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.creationDate')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={getFormattedLocalizedMomentDate('small', order?.creationDate)}
                    data-cy="creation-date-value"
                  />
                </>
              )}
              {!order && <Skeleton type="text" />}
            </div>
          </div>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.shippingDeadline')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={
                      order?.limitShippingDate
                        ? getFormattedLocalizedMomentDate('small', order?.limitShippingDate)
                        : '-'
                    }
                    data-cy="limit-shipping-date-value"
                  />
                </>
              )}
              {!order && <Skeleton type="text" />}
            </div>
          </div>
          <div
            className={
              styles['order-detail__content__tab__content__section__row--inlined__block--text']
            }>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.deliveryDate')} />
            <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
              {order && (
                <>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className={styles['order-detail__content__tab__content__section__icon']}
                  />
                  <Text
                    label={
                      order?.expectedDate
                        ? getFormattedLocalizedMomentDate('small', order?.expectedDate)
                        : '-'
                    }
                    data-cy="delivery-date-value"
                  />
                </>
              )}
              {order?.expectedDate && order?.isUrgent && order?.toManufacture && (
                <Tooltip>
                  <TooltipContent data-cy="late-order-tooltip">
                    {i18next.t('datagrid.status.urgent', { ns: 'orders' })}
                  </TooltipContent>
                  <span>
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className={[
                        styles['order-detail__content__tab__content__section__row--inlined__icon'],
                        styles[
                          'order-detail__content__tab__content__section__row--inlined__icon--urgent'
                        ]
                      ].join(' ')}
                      data-cy="late-order-icon"
                    />
                  </span>
                </Tooltip>
              )}

              {!order && <Skeleton type="text" />}
            </div>
          </div>
        </div>
        {connectedUserPermissions.canViewDentistInfo && (
          <div
            className={[
              styles['order-detail__content__tab__content__section__row'],
              styles['order-detail__content__tab__content__section__row--inlined']
            ].join(' ')}>
            <div
              className={
                styles['order-detail__content__tab__content__section__row--inlined__block--text']
              }>
              <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.dentist')} />
              {order && (
                <Link
                  href={`/users/${order?.dentistEmail}/detail`}
                  label={order?.dentistName || ''}
                  data-cy="dentist-value"
                />
              )}
              {!order && <Skeleton type="text" />}
            </div>
          </div>
        )}

        <Divider />

        <div className={styles['order-detail__content__tab__content__section__content']}>
          <div className={styles['order-detail__content__tab__content__section__row']}>
            <div
              className={styles['order-detail__content__tab__content__section__row--inlined']}
              data-cy="order-step">
              <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.step')} />
            </div>
            <div>
              {order && (
                <OrderDetailTimeline order={order} manufacturingOrder={manufacturingOrder} />
              )}
              {!order && <Skeleton type="text" width="100%" />}
            </div>
          </div>
        </div>

        <div
          className={[
            styles['order-detail__content__tab__content__section__row--inlined__block--text']
          ].join(' ')}>
          <div
            className={styles['order-detail__content__tab__content__section__row--inlined']}
            data-cy="assignee">
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.assignee')} />
          </div>

          <div className={styles['order-detail__content__tab__content__section__row--inlined']}>
            {!order && <Skeleton type="text" />}
            {order && (
              <Text
                label={order?.assigneeName || t('tabs.detail.noAssignee')}
                data-cy="assignee-value"
              />
            )}
          </div>
        </div>
        {isOrderValidated && (
          <div className={styles['order-detail__content__tab__content__section__row']}>
            <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.circleCadVersion')} />
            {manufacturingOrder?.circleCadVersion && (
              <Chips
                color={ColorPropsEnum.WHITE}
                firstLabel={manufacturingOrder.circleCadVersion.label}
              />
            )}
            {manufacturingOrder && !manufacturingOrder?.circleCadVersion && <Text label="-" />}
            {!(order || manufacturingOrder) && <Skeleton type="text" />}
          </div>
        )}

        <div className={styles['order-detail__content__tab__content__section__row']}>
          <Text color={ColorPropsEnum.GREY} label={t('tabs.detail.instructions')} />
          {order && <Text label={order?.instructions || '-'} data-cy="instructions-value" />}
          {!order && <Skeleton type="text" width="100%" />}
        </div>
      </section>
      <Divider />
      <section className={styles['order-detail__content__tab__content__section--large']}>
        <div className={styles['order-detail__content__tab__content__section__teeth-map']}>
          <TeethMapDisplay order={order} displayProductBubbles={true} />
        </div>
        {order && (
          <div className={styles['order-detail__content__tab__content__section__files']}>
            <OrderDetailFile isReadOnly={true} />
          </div>
        )}
        {!order && <Skeleton type="square" width="100%" />}
      </section>
      <section
        className={[
          styles['order-detail__content__tab__content__section--large'],
          styles['order-detail__content__tab__content__section__products']
        ].join(' ')}>
        {order?.items?.map((orderItem) => <OrderItemCard item={orderItem} key={orderItem.id} />)}
        {!order && <Skeleton type="square" width="28%" />}
      </section>
    </>
  );
};

export default DetailTab;
