import { useEffect } from 'react';
import { Text } from '@platform-storybook/circlestorybook';
import { OrderFile } from '../../../../../../models/order';
import { useTranslation } from 'react-i18next';
import {
  displayFileInViewer,
  downloadFile
} from '../../../../../../features/file-manager/file.utils';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import FileManagerContainer from '../../../../../../features/file-manager/FileManagerContainer';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../../../../../store/orders/orders.selectors';
import { downloadFileAction } from '../../../../../../features/file-manager/menu-entry';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../../enum/feedback';
import { FileManagerOrientationProp } from '../../../../../../features/file-manager/file-manager.enum';

type FileManagerProps = {
  isReadOnly: boolean;
};

const OrderDetailFile = ({ isReadOnly }: FileManagerProps) => {
  const { t } = useTranslation(['fileManager']);
  const dispatch = useAppDispatch();

  // UseState
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);
  const orderFiles = useAppSelector(orderFilesSelector);

  useEffect(() => {
    displayFileInViewer(orderFiles, orderFiles[orderFiles.length - 1], dispatch);
  }, [orderFiles]);

  const handleDownloadFileClick = (file?: OrderFile): void => {
    dispatch(
      feedbackActions.setToast({
        message: t('actions.downloadFile.pending'),
        type: ToastType.INFO
      })
    );
    const fileInState = orderFiles.find((thisFile) => file?.id === thisFile.id);

    downloadFile(fileInState!);
    dispatch(feedbackActions.resetToast());
  };

  const fileActions = (file: OrderFile) => [[downloadFileAction(file, handleDownloadFileClick)]];

  return (
    <>
      {orderFiles?.length > 0 && (
        <FileManagerContainer
          orientation={FileManagerOrientationProp.PORTRAIT}
          filesList={orderFiles}
          isViewerLoading={isLoadingFiles}
          isReadOnly={isReadOnly}
          file3DToDisplay={file3dToDisplay}
          fileTextureToDisplay={fileTextureToDisplay}
          fileImageToDisplay={fileImageToDisplay}
          fileActions={fileActions}
          onClickFileCallback={(selectedFile: OrderFile) =>
            displayFileInViewer(orderFiles, selectedFile, dispatch)
          }
        />
      )}
      {!orderFiles?.length && <Text label={t('noFile')} />}
    </>
  );
};
export default OrderDetailFile;
