import {
  WorkflowComponentManufacturingStepEnum,
  WorkflowPendingStepEnum,
  WorkFlowPendingStepPreModelingEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum
} from '../enum/workflow-step';
import { OrderItemComponent } from '../models/order';
import { ProductCategory } from '../enum/product.enum';
import { ColorPropsEnum, MaterialColorEnum } from '../enum/color.enum';
import { ComponentType, MaterialEnum } from '../enum/component-type';
import { PositionKey } from '../models/position';
import { sortedPositionsNumber } from '../enum/position.enum';
import { publicImagesUrl } from './utils';
import { ManufacturingOrder } from '../models/manufacturing-order';

export const defaultProductIconUrl = `${publicImagesUrl}gear-solid.svg`;

export const isSubmitted = (
  currentStep?: WorkflowStepEnum | WorkFlowPendingStepPreModelingEnum
): boolean =>
  (currentStep &&
    !(
      currentStep === WorkFlowStepPreModelingEnum.SUBMITTING ||
      currentStep === WorkFlowPendingStepPreModelingEnum.PENDING_VALIDATION
    )) as boolean;

export const isValidated = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    !isInPendingStatus(currentStep) &&
    !Object.values(WorkFlowStepPreModelingEnum).includes(
      currentStep as WorkFlowStepPreModelingEnum
    )) as boolean;

export const isInPendingStatus = (
  currentStep?: WorkflowStepEnum | WorkflowPendingStepEnum
): boolean =>
  (currentStep &&
    Object.values(WorkflowPendingStepEnum).includes(
      currentStep as WorkflowPendingStepEnum
    )) as boolean;

export const isModelingStep = (currentStep: string): boolean =>
  (currentStep &&
    (currentStep === WorkflowPendingStepEnum.PENDING_MODELING ||
      currentStep === WorkflowStepEnum.MODELING ||
      currentStep === WorkflowPendingStepEnum.PENDING_MODELING_ANALYZE ||
      currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
      currentStep === WorkflowStepEnum.MODELING_PREPARE ||
      currentStep === WorkflowStepEnum.MODELING_REGISTER ||
      currentStep === WorkflowStepEnum.MODELING_DESIGN ||
      currentStep === WorkflowStepEnum.MODELING_EXPORT)) as boolean;

export const hasDeliveryStarted = (currentStep: WorkflowStepEnum): boolean => {
  return [WorkflowStepEnum.DELIVERY, WorkflowStepEnum.SHIPPED, WorkflowStepEnum.DELIVERED].includes(
    currentStep as unknown as WorkflowPostModelingStepEnum
  );
};

export const getNonPendingStep = (
  currentStep?: WorkflowPendingStepEnum | WorkflowStepEnum
): WorkflowStepEnum | undefined => {
  if (!isInPendingStatus(currentStep)) {
    return currentStep as WorkflowStepEnum;
  }
  const nonPendingStepKey = currentStep?.replace('pending_', '').toUpperCase();
  if (nonPendingStepKey && nonPendingStepKey in WorkflowStepEnum) {
    return WorkflowStepEnum[nonPendingStepKey as keyof typeof WorkflowStepEnum];
  }
};

export const isModeled = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    [
      WorkflowStepEnum.MANUFACTURING,
      WorkflowStepEnum.CONTROL,
      WorkflowStepEnum.ASSEMBLY,
      WorkflowStepEnum.SHIPPED,
      WorkflowStepEnum.DELIVERY,
      WorkflowStepEnum.DELIVERED
    ].includes(currentStep as WorkflowPostModelingStepEnum)) as boolean;

/**
 * Determines if a given product category is considered as provisional.
 *
 * @param {ProductCategory} category - The product category to check.
 * @returns {boolean} - True if the category is provisional, false otherwise.
 */
export const isCategoryProvisional = (category: ProductCategory): boolean => {
  return category === ProductCategory.PROVISIONAL;
};

export const getMaterialColor = (material: MaterialEnum) => {
  return Object.keys(MaterialColorEnum).includes(material)
    ? ColorPropsEnum[material.replace('-', '_') as keyof typeof ColorPropsEnum]
    : ColorPropsEnum.WHITE;
};

export const sortTeethPosition = (teethPosition: PositionKey[]): PositionKey[] => {
  const sortedTeethPosition = [...teethPosition];
  const orderedPositions = [...sortedPositionsNumber];
  return sortedTeethPosition.sort(
    (a, b) => orderedPositions.indexOf(a) - orderedPositions.indexOf(b)
  );
};

const priorityComponentSort: string[] = [
  ComponentType.TOOTH,
  ComponentType.PARTIAL_TOOTH,
  ComponentType.GINGIVA,
  ComponentType.FRAME
];

export const sortComponents = (a: OrderItemComponent, b: OrderItemComponent): number => {
  const indexA = priorityComponentSort.indexOf(a.componentType);
  const indexB = priorityComponentSort.indexOf(b.componentType);

  if (indexA === -1 && indexB === -1) {
    // if A and B are not in the priority list, alphabetical sort
    return a.componentType.localeCompare(b.componentType);
  } else if (indexA === -1) {
    // If A is not in priority list, B first
    return 1;
  } else if (indexB === -1) {
    // If B is not in priority list, A first
    return -1;
  } else {
    return indexA - indexB;
  }
};

/**
 * Find the most advanced manufacturing step that has been reached by ALL components of all products of the manufacturing order
 * Examples:
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 *   - 1 component is DESIGNED, all others are MANUFACTURED -> return DESIGNED
 *   - 1 component is DESIGNED, all others are TO_MANUFACTURE -> return DESIGNED
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 * @param manufacturingOrder
 */
export const getMostAdvancedCommonManufacturingStep = (
  manufacturingOrder: ManufacturingOrder
): WorkflowComponentManufacturingStepEnum => {
  const allComponentsSteps = manufacturingOrder.products
    .map((product) => product.components.map((component) => component.manufacturingStep))
    .flat();
  let mostAdvancedStep: WorkflowComponentManufacturingStepEnum | undefined = undefined;

  // We want the most advanced step that has been reach by ALL components
  // Iterate through WorkflowComponentManufacturingStepEnum from the least advanced step to the most advanced
  // If at least one of the components is at this step : this is it
  Object.values(WorkflowComponentManufacturingStepEnum).forEach((step) => {
    const componentsInThisStep = allComponentsSteps.filter(
      (componentStep) => componentStep === step
    );
    if (!mostAdvancedStep && componentsInThisStep.length > 0) {
      mostAdvancedStep = step;
    }
  });

  return mostAdvancedStep ?? WorkflowComponentManufacturingStepEnum.TO_DESIGN;
};
