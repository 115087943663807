export enum InjectionModeEnum {
  NONE = 'NONE',
  SELECTABLE = 'SELECTABLE'
}

export enum CircleSoftwareCompatibilityEnum {
  NONE = 'NONE',
  CHAIRSIDE = 'CHAIRSIDE',
  ONEDESIGN = 'ONEDESIGN',
  CIRCLECAD = 'CIRCLECAD'
}

export enum CircleCadProductTypeEnum {
  removable = 'removable',
  COURONNE_ESTHE_MONO = 'COURONNE_ESTHE_MONO',
  COURONNE_METAL = 'COURONNE_METAL',
  COURONNE_CERAM_STRATIFIEE = 'COURONNE_CERAM_STRATIFIEE',
  ENDOCOURONNE = 'ENDOCOURONNE',
  INLAY = 'INLAY',
  ONLAY = 'ONLAY',
  VENEER = 'VENEER',
  VENEER_PROVISOIRE = 'VENEER_PROVISOIRE',
  OVERLAY = 'OVERLAY',
  BRIDGE_MONO_METAL = 'BRIDGE_MONO_METAL',
  BRIDGE_ESTHE_MONO = 'BRIDGE_ESTHE_MONO',
  BRIDGE_CERAM_STRATIFIE = 'BRIDGE_CERAM_STRATIFIE',
  COURONNE_PROVISOIRE = 'COURONNE_PROVISOIRE',
  BRIDGE_PROVISOIRE = 'BRIDGE_PROVISOIRE',
  INLAYCORE = 'INLAYCORE'
}

export enum CardDisplayMode {
  PRINT = 'print',
  DEFAULT = 'default',
  TOOLTIP = 'tooltip'
}

export enum ProductCategory {
  UNIT = 'UNIT',
  BRIDGE = 'BRIDGE',
  PROVISIONAL = 'PROV',
  DEFINITIVE = 'DEFINITIVE',
  BAR = 'BAR',
  SNAP_ON_DENTURES = 'SNAP_ON_DENTURES'
}

export enum Family {
  REMOV = 'REMOV',
  FIXED = 'FIXED',
  IMPLANT = 'IMPLANT',
  GUARDS = 'GUARDS',
  OCCLUSION_RIMS = 'OCR'
}

export enum TeethMode {
  SINGLE_RANGE = 'single-range',
  MULTI_RANGE = 'multi-range',
  SINGLE_TOOTH = 'single-tooth',
  MULTI_TEETH = 'multi-teeth',
  FULL_ARCH = 'full-arch',
  MULTI_ARCH = 'multi-arch'
}
