import FileManager from './file-manager-content/FileManager';
import { FileManagerOrientationProp } from './file-manager.enum';
import { useEffect, useState } from 'react';
import ZoomManager from './zoom-manager/ZoomManager';
import { useTranslation } from 'react-i18next';
import { FileManagerProps } from './file-manager.model';
import styles from './file-manager-container.module.scss';
import { FileLabel } from '../../models/common-types';
import { FileLabelEnum } from '../../enum/file-label';
import { useGetCommonTypesQuery } from '../../services/common-types-rtkq.services';

const FileManagerContainer = (props: FileManagerProps) => {
  const { data: commonTypes } = useGetCommonTypesQuery();
  const { t } = useTranslation(['fileManager']);
  const [hiddenByModal, setHiddenByModal] = useState(false);

  const fileLabelsAllowed: Array<string> = Object.values(FileLabelEnum);
  const labelsList = commonTypes?.fileLabels
    ?.filter((fileLabel: FileLabel) => fileLabelsAllowed.includes(fileLabel.code))
    ?.map((fileLabel: FileLabel) => {
      return {
        label: t(`file.labels.${fileLabel.code}`, { ns: 'orderDetail' }),
        value: fileLabel.code,
        image: fileLabel.imageUrl
      };
    });

  /**
   * Reset selection when we close or open the modal to avoid any incoherence.
   */
  useEffect(() => {
    if (props.onClickFileCallback)
      props.onClickFileCallback(props.filesList[props.filesList.length - 1]);
  }, [hiddenByModal]);

  return (
    labelsList &&
    (props.orientation === FileManagerOrientationProp.LANDSCAPE || !props.orientation ? (
      <>
        <ZoomManager
          canUploadFile={!!props.onUploadFileCallback}
          setHiddenByModal={setHiddenByModal}>
          <FileManager
            {...props}
            labelsList={labelsList}
            height={'100%'}
            className={styles['file-manager-container__modal']}
            isReadOnly={true}
            isModal={true}
          />
        </ZoomManager>

        {!hiddenByModal && <FileManager {...props} isReadOnly={true} labelsList={labelsList} />}
      </>
    ) : (
      <FileManager {...props} labelsList={labelsList} />
    ))
  );
};

export default FileManagerContainer;
