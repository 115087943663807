import { BubblePicto, Chips, Text } from '@platform-storybook/circlestorybook';
import { OrderItem } from '../../../../../../models/order';
import styles from './order-item-tooltip.module.scss';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../../../../models/product';
import { ProductCategory } from '../../../../../../enum/product.enum.ts';
import { ComponentType } from '../../../../../../enum/component-type.ts';
import {
  isCategoryProvisional,
  defaultProductIconUrl
} from '../../../../../../utils/order.utils.ts';
import { getLocalizedProperty } from '../../../../../../utils/utils.tsx';

type OrderItemTooltipProps = {
  item: OrderItem;
};
const OrderItemTooltip = ({ item }: OrderItemTooltipProps) => {
  const { t } = useTranslation();
  const familyColor = item.product?.family
    ? (`family-${item.product.family.toLowerCase()}` as ColorPropsEnum)
    : ColorPropsEnum.WHITE;
  const frameComponent = item.itemComponents?.find(
    (itemComponent) => itemComponent.componentType === ComponentType.FRAME
  );
  return (
    <div className={styles['order-item-tooltip']}>
      <div className={styles['order-item-tooltip__bubble']}>
        <BubblePicto
          backgroundColor={ColorPropsEnum.PURPLE_MEDIUM}
          color={familyColor}
          size="large"
          isDashedBorder={isCategoryProvisional(item.product.category as ProductCategory)}
          url={item.product?.imageUrl ?? defaultProductIconUrl}
        />
      </div>
      <div className={styles['order-item-tooltip__info--main']}>
        <Text
          label={item.product[getLocalizedProperty('label') as keyof Product] || ''}
          type="text"
          color={ColorPropsEnum.WHITE}
          italic={true}
          bold={true}
          size="l"
          className={styles['order-item-tooltip__row']}
        />
        {item.product?.category && (
          <Chips
            firstLabel={t(`products.categories.${item.product.category.toUpperCase()}`, {
              ns: 'catalog'
            })}
            color={familyColor}
            className={styles['order-item-tooltip__row']}
          />
        )}
        {item.product?.class && (
          <Chips
            firstLabel={t(`products.classes.${item.product.class.toUpperCase()}`, {
              ns: 'catalog'
            })}
            color={familyColor}
            className={styles['order-item-tooltip__row']}
          />
        )}
        {frameComponent?.material && (
          <Text
            label={t(`material.${frameComponent.material.code.toUpperCase()}`, {
              ns: 'component'
            })}
            type="text"
            color={ColorPropsEnum.WHITE}
            size="m"
            className={styles['order-item-tooltip__row']}
          />
        )}
      </div>
    </div>
  );
};

export default OrderItemTooltip;
