export enum CursorEnum {
  POINTER = 'pointer',
  NOT_ALLOWED = 'not-allowed',
  START = 'start',
  END = 'end',
  ADD = 'add',
  REMOVE = 'remove'
}

/**
 * Object containing not allowed cursor for all keyed tooth numbers.
 *
 * @type {Object<number, CursorEnum>}
 */
export const NotAllowedCursors: { [key: number]: CursorEnum } = {
  18: CursorEnum.NOT_ALLOWED,
  17: CursorEnum.NOT_ALLOWED,
  16: CursorEnum.NOT_ALLOWED,
  15: CursorEnum.NOT_ALLOWED,
  14: CursorEnum.NOT_ALLOWED,
  13: CursorEnum.NOT_ALLOWED,
  12: CursorEnum.NOT_ALLOWED,
  11: CursorEnum.NOT_ALLOWED,
  21: CursorEnum.NOT_ALLOWED,
  22: CursorEnum.NOT_ALLOWED,
  23: CursorEnum.NOT_ALLOWED,
  24: CursorEnum.NOT_ALLOWED,
  25: CursorEnum.NOT_ALLOWED,
  26: CursorEnum.NOT_ALLOWED,
  27: CursorEnum.NOT_ALLOWED,
  28: CursorEnum.NOT_ALLOWED,
  48: CursorEnum.NOT_ALLOWED,
  47: CursorEnum.NOT_ALLOWED,
  46: CursorEnum.NOT_ALLOWED,
  45: CursorEnum.NOT_ALLOWED,
  44: CursorEnum.NOT_ALLOWED,
  43: CursorEnum.NOT_ALLOWED,
  42: CursorEnum.NOT_ALLOWED,
  41: CursorEnum.NOT_ALLOWED,
  31: CursorEnum.NOT_ALLOWED,
  32: CursorEnum.NOT_ALLOWED,
  33: CursorEnum.NOT_ALLOWED,
  34: CursorEnum.NOT_ALLOWED,
  35: CursorEnum.NOT_ALLOWED,
  36: CursorEnum.NOT_ALLOWED,
  37: CursorEnum.NOT_ALLOWED,
  38: CursorEnum.NOT_ALLOWED
};

export enum ToolEnum {
  MISSING = 'MISSING',
  EXTRACT = 'EXTRACT',
  STUMP = 'STUMP'
}
